//-
//- Main Style
//-

p{

    margin      : 0;
    line-height : 1.6;

}
.container{

    width : 1000px;

    &.wide_container{

        width : 1140px;

    }

}
.m-header.m-tglBtn-wrapper.mm-slideout{
    display : none;
}
.header{

    border-bottom : 5px solid $baseColor;

    &__info{

        justify-content : space-between;

    }

    &__logo{

        margin-left : 10%;
        padding-top : 25px;

        a{

            display : block;

        }

    }

    &__tell,
    &__mail{

        font-weight    : bold;
        width          : 270px;
        padding-top    : 15px;
        padding-bottom : 15px;

    }

    &__tell{

        background-image    : url($imgPath + "/phone.svg");
        background-repeat   : no-repeat;
        background-size     : 40px;
        padding-left        : 52px;
        background-position : 0 center;

        p{

            font-size      : 3.3rem;
            line-height    : 1.1;
            letter-spacing : -2px;

        }

        span{

            font-size : 1.4rem;
            display   : block;

        }

    }

    &__mail{

        text-align        : center;
        background-image  : url($imgPath + "/sla.svg");
        background-repeat : no-repeat;
        background-size   : cover;

        a{

            color       : #fff;
            line-height : 1;

        }

    }

    &__mail-time{

        font-size : 3.3rem;

    }
    &__mail-accept{

        font-size : 1.8rem;
        position  : relative;
        bottom    : 1px;

    }

    &__mail-ex{

        display : block;

    }

}
.mainV{

    img{

        width : 100%;

    }

}
.top_headline_sub{
    padding-top : 150px;
}
.top_headline_sub h2,
.top_headline_main h2{
    text-shadow : 1px 1px 0 rgba(0, 0, 0, .2);
}
.top_headline_sub h2{

    font-size      : 27px;
    font-weight    : bold;
    border-bottom  : 3px solid $accentColor;
    margin-right   : 340px;
    padding-bottom : 5px;
    margin-bottom  : 10px;

}
.top_headline_main h2{
    font-size   : 55px;
    font-weight : bold;
}
.gnav{

    background-color : $baseColor;
    box-shadow       : 0px 3px 2px 1px;
    padding-top      : 15px;
    padding-bottom   : 15px;

    &_each{

        border-right : 1px solid #4a58a7;
        border-left  : 1px solid #4a58a7;

        & + &{

            border-left  : 0;
            border-right : 1px solid #4a58a7;

        }

        a{

            color          : #fff;
            font-size      : 1.6rem;
            text-align     : center;
            font-weight    : bold;
            padding-top    : 15px;
            padding-bottom : 15px;
            display        : block;

            transition     : all 0.2s 0s ease;

            &:hover{

                color : #ccd8ff;

            }

        }

    }

}
.border_line{

    margin           : 40px auto;
    width            : 150px;
    height           : 3px;
    background-color : $baseColor;

}
.top_sentense{

    text-align       : center;
    background-image : url($imgPath + '/pic2.png');
    background-size  : cover;

    p{

        font-weight : bold;
        font-size   : 2rem;

    }

    h2{

        font-size   : 4rem;
        color       : $baseColor;
        font-weight : bold;

    }

    h3{

        font-size   : 3.6rem;
        color       : $baseColor;
        font-weight : bold;

        span{

            font-size   : 21px;
            display     : inline-block;
            margin-left : 12px;
            position    : relative;
            top         : 1px;

        }

    }

    &-img{

        margin-top    : 40px;
        margin-bottom : 50px;

    }

    &-btm{

        border           : 3px solid $baseColor;
        background-color : rgba(255, 255, 255, 0.8);
        box-shadow       : 0 2px 2px rgba(0, 0, 0, 0.2);

        &.l-m{

            margin-bottom : 0;

        }

        &.l-p{

            padding-top    : 50px;
            padding-bottom : 45px;

        }

        .ad-3,
        .ad-tsu{

            color      : $accentColor;
            position   : relative;
            font-style : italic;
            display    : inline-block;

        }

        .ad-3{

            font-size    : 4.5rem;
            line-height  : 1;
            top          : 3px;
            margin-right : 3px;

        }

        .ad-tsu{

            margin-right : 5px;

        }

    }

}
.subtitle{

    background-color : $baseColor;
    text-align       : center;

    h2{

        padding     : 45px 0 42px;
        color       : #fff;
        font-size   : 3.3rem;
        font-weight : bold;
        margin      : 0;

    }

    &__line,
    &__en{

        display     : inline-block;
        font-weight : 100;
        line-height : 1;
        position    : relative;

    }

    &__line{

        font-size    : 21px;
        margin-left  : 12px;
        margin-right : 12px;
        bottom       : 7px;

    }

    &__en{

        font-family : abel;
        font-size   : 18px;
        bottom      : 6px;

    }

}
//サービス
.service_each{

    background-color    : $baseColor;
    background-image    : url($imgPath + "/service_bgImg.svg");
    background-repeat   : no-repeat;
    background-position : right -50px;
    border-radius       : 3px;
    padding             : 30px 50px 35px;
    margin-bottom       : 30px;

    &:nth-last-of-type(1){

        margin-bottom : 0;

    }

    h4{

        padding-left  : 12px;
        color         : $accentColor;
        font-weight   : bold;
        font-size     : 3.3rem;
        margin-bottom : 15px;
        line-height   : 1.25;

        span{

            display      : inline-block;
            margin-right : 10px;
            font-size    : 4.5rem;
            position     : relative;
            top          : 4px;

        }

    }

    p{

        color       : #fff;
        font-size   : 1.8rem;
        font-weight : bold;

    }

    &_left{

        display         : flex;
        justify-content : center;
        flex-direction  : column;

    }

    &_right{

        img{

            width   : 200px;
            display : block;
            margin  : auto;

        }

    }

    &_right2{

        img{

        }

    }

    &_right3{

        img{

        }

    }

}
.top_service_click{

    margin-top : 50px;

    a{

        text-align       : center;
        background-color : #fff;
        color            : #222;
        border           : 1px solid black;
        border-radius    : 3px;
        padding          : 15px 0;
        font-weight      : bold;
        font-size        : 2rem;
        display          : block;
        width            : 320px;
        margin           : auto;
        box-shadow       : 0 2px 2px rgba(0, 0, 0, 0.2);

    }

}
//オフィス
.top_office{

    background-image : url($imgPath + '/pic6.png');
    background-size  : cover;

    &.l-p{

        padding-top    : 90px;
        padding-bottom : 85px;

    }

    &-wrapper{

        justify-content : space-around;

    }

    &_left{

        position : relative;
        right    : 5%;

        p{

            line-height   : 1.4;
            margin-bottom : 10px;
            padding-right : 5px;
            text-align    : right;
            font-weight   : bold;

        }

    }

    &_link{

        text-align : center;
        margin-top : 40px;

        a{

            text-align       : center;
            background-color : #fff;
            color            : #222;
            border           : 1px solid black;
            border-radius    : 3px;
            padding          : 15px 0;
            font-weight      : bold;
            font-size        : 2rem;
            display          : block;
            width            : 320px;
            margin           : auto;
            box-shadow       : 0 2px 2px rgba(0, 0, 0, 0.2);

        }

    }

}
.top_tokyo_office{

    img{

        width : 350px;

    }

    h4{

        padding-top   : 85px;
        text-align    : right;
        font-weight   : bold;
        margin-bottom : 30px;
        font-size     : 3.5rem;

    }

}
.japan-map{

    &-img{

        position : relative;

    }

    &-sendai{

        position : absolute;
        top      : 350px;
        right    : 30px;

    }

    &-tokyo{

        position : absolute;
        top      : 475px;
        right    : 90px;

    }

    &-fukui{

        position : absolute;
        top      : 425px;
        left     : 160px;

    }

    &-osaka{

        position : absolute;
        top      : 510px;
        left     : 120px;

    }

    &-seiyo{

        position : absolute;
        top      : 610px;
        left     : 175px;

    }

    &-nagoya{

        position : absolute;
        top      : 550px;
        right    : 185px;

    }

    &-link{

        top    : 0;
        height : 100%;
        width  : 100%;

        a{

            padding          : 8px 15px;
            border           : 1px solid white;
            border-radius    : 2px;
            background-color : rgba(14, 28, 99, .8);
            color            : #fff;
            font-weight      : bold;
            font-size        : 1.6rem;
            box-shadow       : 0 2px 2px rgba(0, 0, 0, .5);

        }

    }

}
.footer{

    background-color : $baseColor;
    text-align       : center;
    color            : #fff;
    font-size        : 14px;

    &_logo{

        img{

            margin : 45px 0px;

        }

    }

    &_faq_left{

        display         : flex;
        justify-content : center;
        flex-direction  : column;

        p{

            font-size   : 1.8rem;
            font-weight : bold;

        }

    }

    &_faq_right{

        a{

            text-align       : center;
            border           : 1px solid #fff;
            border-radius    : 3px;
            background-color : rgba(255, 255, 255, 0.14);
            box-shadow       : 0 2px 2px rgba(0, 0, 0, .7);
            padding          : 15px 0;
            color            : #fff;
            font-weight      : bold;
            font-size        : 2rem;
            display          : block;
            width            : 320px;
            margin           : auto;
            box-shadow       : 0 2px 2px rgba(0, 0, 0, 0.2);

        }

    }

    &_faq{

        padding       : 30px 0;
        border-bottom : 1px solid #2d3d8a;

    }

    &_link{

        margin-top      : 50px;
        margin-bottom   : 50px;
        justify-content : center;

        a{

            display        : inline-block;
            padding-top    : 7px;
            padding-bottom : 7px;
            color          : #fff;
            text-align     : center;

        }

        &-items{

            margin-right : 30px;

        }

    }

    &_logo{

        background-color : #000048;
    }

}
.copyright{

    padding-bottom : 10px;
    font-size      : 1.2rem;

}
.top_news{

    &_category,
    &_date{

        float : left;

    }

    &_category{

        margin-right : 10px;

        p{

            padding          : 5px 15px 4px;
            border-radius    : 3px;
            background-color : #ffd690;
            font-size        : 12px;
            font-weight      : bold;
            line-height      : 1;
            display          : inline-block;

        }

    }

    &_date{

        p{

            font-weight : bold;
            line-height : 1;
            padding-top : 5px;

        }

    }

    &_detail{

        margin-bottom : 5px;

    }

    &_sentence{

        p{

            padding-top    : 3px;
            padding-bottom : 5px;

        }

    }

    &_each{

        margin-bottom : 20px;

    }

    &_sentence{

        padding-bottom : 10px;
        border-bottom  : 1px dotted #ccc;

    }

    &{

        margin : 50px 0;

    }

}
.staff{

    flex-wrap : wrap;

    &.container{

        width : 1140px;

    }

    //- *******************
    //- スタッフ一覧ページ
    //- *******************
    &-each{

        width         : 24%;
        margin-bottom : 20px;
        margin-right  : 10px;
        box-shadow    : $boxShadow;

        &:nth-of-type(4n){

            margin-right : 0;

        }

    }

    &-each-top-slash{

        img{

            width : 100%;

        }

    }

    &-each-bottom{

        background-color    : $baseColor;
        text-align          : center;
        color               : white;
        background-image    : url($imgPath + "/sla-u-2.png");
        background-position : left bottom;
        background-repeat   : no-repeat;
        background-size     : contain;

    }

    &-each-bottom-name{

        padding-top    : 12px;
        padding-bottom : 12px;

        p{

            font-weight : bold;
            font-size   : 1.8rem;

        }

        span{

            display   : block;
            font-size : 12px;

        }

    }

    &-each-top-face{

        background-image    : url($imgPath + "/sla-t-2.png");
        background-position : left top;
        background-repeat   : no-repeat;
        background-size     : contain;
        padding-top         : 20px;
        padding-bottom      : 20px;

        img{

            width            : 140px;
            border           : 4px solid #f9f9f9;
            border-radius    : 50%;
            display          : block;
            margin           : auto;
            background-color : #fff;

        }

    }

    //- *******************
    //- スタッフ個別ページ
    //- *******************
    &-single{

        border              : 3px solid $baseColor;
        box-shadow          : $boxShadow;
        background-image    : url($imgPath + '/sla-t.png'), url($imgPath + '/sla-u.png');
        background-repeat   : no-repeat, no-repeat;
        background-position : left top, right bottom;
        background-size     : contain, contain;
        padding             : 80px 30px 70px;
        width               : 800px;

    }

    &-single-content-left,
    &-single-content-right{

        float : left;

    }

    &-single-content,
    &-single-content-explain{

        padding : 0px 30px;

    }

    &-single-content-right{

        h2{

            color         : $baseColor;
            font-size     : 3.3rem;
            font-weight   : bold;
            margin-bottom : 20px;

        }

        p{

            font-size   : 1.8rem;
            line-height : 1.7;
            font-weight : bold;

        }

    }

    &-single-content-explain{

        margin-top : 40px;

        h3{

            font-weight   : bold;
            font-size     : 2.3rem;
            margin-bottom : 12px;

        }

    }

    &-single-content-right{

        margin-left : 80px;
        padding-top : 10px;

    }

    &-single-bottom-slash{

        margin-top : -15px;

    }

}
.servicedetail_page{

    margin : 60px 0;

}
.servicedetail_title h2{
    font-size   : 30px;
    font-weight : bold;
}
.servicedetail_gaiyo{
    background-color : #f3f3f3;
    padding          : 20px;
    border-radius    : 3px;
    font-weight      : bold;
    margin-top       : 15px;
}
.servicedetail_content h3{
    text-align       : center;
    background-color : $baseColor;
    color            : white;
    font-size        : 25px;
    font-weight      : bold;
    padding          : 10px;
    margin-top       : 40px;
    margin-bottom    : 20px;
    border-radius    : 3px;
}
.servicedetail_content h4{
    margin-top  : 20px;
    padding     : 0.25em 0.5em; /*上下 左右の余白*/
    color       : #494949; /*文字色*/
    background  : transparent; /*背景透明に*/
    border-left : solid 5px #7db4e6; /*左線*/
    font-weight : bold;
    font-size   : 20px;
}
.servicedetail_content ul li{
    list-style  : disc;
    margin-left : 20px;
}
p.asta{
    font-size  : 14px;
    margin-top : 10px;
}
.faq_barner{
    text-align    : center;
    margin-top    : 50px;
    margin-bottom : 60px;
}
.faq_barner img{
    width : 70%;
}
.servicedetail_content p{
    line-height : 24px;
}
.service_subtitle{
    margin-top    : 60px;
    margin-bottom : 30px;
}
.service_subtitle_jap{
    color       : $baseColor;
    font-size   : 29px;
    font-weight : bold;
    padding     : .25em 0 .25em .75em;
    border-left : 4px solid $baseColor;
}
.service_subtitle_eng{
    color       : #7d7b7b;
    margin-left : 10px;
    font-size   : 25px;
}
.service_subtitle_dot{
    color       : $baseColor;
    font-weight : bold;
    font-size   : 29px;
    margin-left : 10px;
}
.service_table_img{
    background-color : $baseColor;
    padding          : 0 10px;
    width            : 85px;
    height           : 90px;
}
.service_table_img p{
    color       : white;
    text-align  : center;
    padding-top : 6px;
    font-size   : 14px;
    font-weight : bold;
}
.service_table_img img{
    background-color : $baseColor;
    padding          : 7px;
}
.service_table_img, .service_table_title{
    float : left;
}
.service_table_title{
    margin-top  : 32px;
    margin-left : 14px;
}
.service_table_title h3{
    font-size   : 20px;
    font-weight : bold;
    color       : black;
    width       : 365px;
}
.service_table_each:hover{
    opacity : 0.7;
}
.service_table{
    margin-bottom : 30px;
}
.service_page{
    padding-bottom : 40px;
}
.saiyo h2{
    margin           : 40px 0 25px 0;
    font-size        : 25px;
    background-color : $baseColor;
    color            : white;
    padding          : 15px;
    font-weight      : bold;
}
.row.saiyo_each_col{
    padding-bottom : 15px;
    margin-bottom  : 15px;
    border-bottom  : 1px dotted #ccc;
}
.saiyo_title p{
    font-weight : bold;
}
.saiyo_intro_img, .saiyo_intro_sentence{
    float : left;
}
.saiyo_intro_sentence{
    padding : 40px 15px;
}
.saiyo_intro_sentence p{
    font-size   : 18px;
    line-height : 25px;
    font-weight : bold;
}
.saiyo{
    margin : 35px 0px 60px 0;
}
.saiyo_intro{
    width         : 700px;
    margin        : auto;
    margin-bottom : -49px;
}
.office_page{
    margin : 60px 0;
}
.tokyo_office_img img{
    width      : 420px;
    box-shadow : 28px 28px 0px #ccc;
    margin-top : 20px;
}
.fukui_office_img{
    text-align : right;
}
.fukui_office_img img{
    width      : 420px;
    box-shadow : -28px 28px 0px #ccc;
    margin-top : 20px;
}
.office_title{
    border-bottom : 3px solid #ccc;
    padding-left  : 3px;
    margin-bottom : 10px;
    width         : 80%;
}
.office_title h2{
    font-weight : bold;
    font-size   : 21px;
}
.office_add p{
    padding-left : 3px;
}
.office_map{
    margin-top : 10px;
    border     : 2px solid #cccccc;
}
.office_col{
    margin-bottom : 60px;
}
.office_title h2{
    float : left;
}
.office_title h3{
    font-size     : 44px;
    margin-top    : -16px;
    color         : #ccc;
    font-family   : 'Meta Normal', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin-bottom : -3px;
    padding-right : 10px;
    float         : right;
}
.jyoho_odd, .jyoho_even{
    padding    : 10px 0;
    box-shadow : $boxShadow;
}
.jyoho_odd{
    border : 1px solid #ccc;
}
.jyoho_even{
    background-color : #ccc;
}
.jyoho_sentense{
    margin : 50px 0;
}
.jyoho_left{
    text-align  : center;
    font-weight : bold;
}
.jyoho_right_office{
    margin-bottom : 10px;
}
.gaiyo_nav{
    margin-top : 40px;
}
.gaiyo_title_name, .gaiyo_map{
    float : left;
}
.gaiyo_map{
    margin-top : -4px;
}
.gaiyo_map a{
    background-color : $baseColor;
    color            : white;
    padding          : 2px 5px;
    border-radius    : 3px;
    margin-left      : 5px;
    font-size        : 13px;
}
.jisseki_box{
    border : 1px solid $baseColor;
    width  : 32%;
    float  : left;
    height : 140px;
}
.jisseki_box + .jisseki_box{
    margin-left : 2%;
}
.jisseki_title{
    width            : 40%;
    background-color : $baseColor;
    color            : white;
    text-align       : center;
    font-weight      : bold;
    padding          : 3px;
}
.jisseki_sentense{
    margin         : 10px;
    padding-bottom : 10px;
    color          : $baseColor;
    border-bottom  : 1px dotted $baseColor;
    height         : 55px;
}
.jisseki_client{
    margin      : 10px;
    border      : 1px solid $baseColor;
    text-align  : center;
    padding     : 4px;
    font-weight : bold;
    color       : $baseColor;
}
.jisseki_sentense p{
    font-size   : 17px;
    line-height : 22px;
}
.jisseki_col{
    margin-bottom : 17px;
}
.jisseki_box.jisseki_box2{
    background-color : $baseColor;
    padding          : 35px;
    font-weight      : bold;
}
.jisseki_box.jisseki_box2 a{
    color : white;
}
.jisseki_client p{
    font-size : 14px;
}
.bread{

    padding          : 5px 0 3px;
    background-color : #f1f1f1;
    font-size        : 1.3rem;

    span{

        display      : inline-block;
        margin-left  : 6px;
        margin-right : 7px;
        position     : relative;
        bottom       : 1px;

        &.current-page{

            margin-left : 0;
            bottom      : 0;

        }

    }

}
.gaiyo_sentense{

    &.l-m{

        margin-top : 50px;

    }

    p{

        font-weight : bold;
        font-size   : 1.8rem;
        text-align  : center;

    }

}
.kasou_pic{

    height           : 160px;
    border-bottom    : 3px solid $accentColor;
    background-image : url($imgPath + '/kasou-top.png');
    background-size  : cover;

}
.kasou_headline_main h2{
    text-shadow : 1px 1px 0 rgba(0, 0, 0, .2);
    color       : white;
    font-size   : 36px;
    text-align  : center;
    padding     : 60px;
    font-weight : bold;
}
.gaiyo{

    &_first_box a,
    &_second_box a{

        color       : black;
        font-size   : 19px;
        font-weight : bold;
        display     : block;
        padding     : 30px

    }
    &_left_box,
    &_right_box{

        float         : left;
        width         : 49%;
        text-align    : center;
        border        : 1px solid black;
        border-radius : 3px;
        box-shadow    : $boxShadow;

    }
    &_right_box{

        float : right;

    }
    &_first_box.clearfix{

        margin-bottom : 15px;

    }
    &_second_box.clearfix{

        margin-bottom : 45px;

    }

}
.faq_page{
    margin : 60px 0;
}
.faq_form{
    background-color : $baseColor;
    color            : white;
    padding          : 70px 60px 100px 60px;
    margin-top       : 30px;
}
.faq_form h2{
    text-align    : center;
    font-size     : 25px;
    font-weight   : bold;
    margin-bottom : 30px;
}
.faq_box_blank{
    background-color : white;
    height           : 30px;
    margin           : 12px;
    border-radius    : 3px;
}
.faq_title{
    padding     : 15px;
    font-weight : bold;
}
.faq_pre_title, .faq_the_title{
    float : left;
}
.faq_pre_title.must{
    background-color : $accentColor red;
    padding          : 3px 5px;
    border-radius    : 5px;
    margin-right     : 10px;
}
.faq_pre_title.not_must{
    background-color : #8e8e8e;
    padding          : 3px 5px;
    border-radius    : 5px;
    margin-right     : 10px;
}
.faq_the_title{
    padding : 3px 5px;
}
.faq_box_blank.faq_box_blank_long{
    height : 130px;
}
.faq_privacy{
    background-color : white;
    color            : black;
    padding          : 30px;
    margin           : 40px;
    border-radius    : 3px;
}
.faq_privacy_agree{
    text-align  : center;
    font-weight : bold;
}
.faq_submit a{
    background-color : white;
    color            : black;
    padding          : 20px 95px;
    font-size        : 18px;
    border-radius    : 3px;
    font-weight      : bold;
}
.faq_submit{
    text-align : center;
    margin-top : 60px;
}
.article_wrapper{

    flex-wrap       : wrap;
    justify-content : space-between;

}
.article{

    width : 49%;

    &_date,
    &_category{
        float : left;
    }

    & img{
        border-radius : 3px;
    }

    &_category{

        position : relative;
        top      : 3px;

        p{

            margin-left      : 10px;
            padding          : 3px 6px 2px;
            border-radius    : 2px;
            background-color : #0e1c63;
            color            : #ffffff;
            font-weight      : bold;
            font-size        : 1.1rem;
            line-height      : 1;

        }

    }

    &_date{

        p{

            color       : #aaaaaa;
            font-weight : bold;
            font-size   : 2rem;
            font-family : Abel;
            line-height : 1.1;

        }

    }

    &_bottom{

        background-color : #ffffff;
        background-image : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cg fill='%230e1c63' fill-opacity='0.34'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");
        height           : 8px;
        margin-top       : 5px;
        margin-bottom    : 30px;

    }

    &-detail{

        margin-top : 15px;

    }

}
.title{

    font-weight : bold;

    p{

        font-size  : 1.8rem;
        margin     : 18px 0 12px;
        color      : #222;
        transition : all 0.2s 0s ease;

        &:hover{

            color : #00b3ee;

        }

    }
}
.blog{

    &.l-m{

        margin-top : 40px;

    }

    &_right{

        padding-left : 0;

        &_category,
        &_search,
        &_popular,
        &_sc,
        &_bar{

            margin-bottom : 15px;
            border-radius : 3px;
            border        : 2px solid $baseColor;
            border-bottom : 5px solid $baseColor;

            h3{

                background-color : $baseColor;
                color            : #fff;
                text-align       : center;
                padding          : 14px 0 13px;
                font-weight      : bold;
                margin-bottom    : 0;

            }

        }

        &_category_each{

            border-bottom : 1px solid $baseColor;

            a{

                display     : block;
                color       : $baseColor;
                text-align  : center;
                font-weight : bold;
                padding     : 20px 0 18px;
                line-height : 1;
                background  : -moz-linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 1%, rgba(255, 255, 255, 1) 50%, rgba(247, 247, 247, 1) 51%, rgba(247, 247, 247, 1) 100%); /* ff3.6+ */
                background  : -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(1%, rgba(255, 255, 255, 1)), color-stop(50%, rgba(255, 255, 255, 1)), color-stop(51%, rgba(247, 247, 247, 1)), color-stop(100%, rgba(247, 247, 247, 1))); /* safari4+,chrome */
                background  : -webkit-linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 1%, rgba(255, 255, 255, 1) 50%, rgba(247, 247, 247, 1) 51%, rgba(247, 247, 247, 1) 100%); /* safari5.1+,chrome10+ */
                background  : -o-linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 1%, rgba(255, 255, 255, 1) 50%, rgba(247, 247, 247, 1) 51%, rgba(247, 247, 247, 1) 100%); /* opera 11.10+ */
                background  : -ms-linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 1%, rgba(255, 255, 255, 1) 50%, rgba(247, 247, 247, 1) 51%, rgba(247, 247, 247, 1) 100%); /* ie10+ */
                background  : linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 1%, rgba(255, 255, 255, 1) 50%, rgba(247, 247, 247, 1) 51%, rgba(247, 247, 247, 1) 100%); /* w3c */
                filter      : progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#F7F7F7', GradientType=0); /* ie6-9 */

            }

        }
        &_search_box{

            padding : 10px;

        }

        &_popular_each{

            border-bottom : 1px solid $baseColor;
            padding       : 5px 0;

            img, p{

                float : left;

            }

            img{

                width         : 39%;
                border-radius : 3px;
                padding-left  : 5px;

            }

            p{

                width     : 61%;
                font-size : 14px;
                padding   : 5px;

            }

        }

        &_sc{

            p{

                padding   : 10px;
                font-size : 1.4rem;

            }

        }

    }

}
.article_capimg img{
    width         : 100%;
    border-radius : 5px;
}
.article_page_title h2{
    font-size : 31px;
    margin    : 15px 0 10px 0;
}
.article_page_date, .article_page_category{
    float        : left;
    margin-right : 10px;
    padding      : 5px 7px;
}
.article_page_detail p{
    font-size : 18px;
}
.article_page_category{
    background-color : $baseColor;
    border-radius    : 5px;
    color            : white;
}
.article_page_writer{
    margin-top       : 20px;
    background-color : #ececec;
    padding          : 20px;
    border-radius    : 5px;
}
.article_page_writer_img, .article_page_writer_detail{
    float : left;
}
.article_page_writer_img{
    width : 15%;
}
.article_page_writer_detail{
    width        : 85%;
    padding-left : 20px;
}
.article_page_writer_img img{
    border-radius : 50%;
    border        : 2px solid #ccc;
}
.article_page_writer_detail h4{
    font-size      : 18px;
    padding-bottom : 5px;
}
.article_page_writer_detail p{
    color : #757474;
}
.article_page_sentense{
    margin-top : 30px;
}
.article_page_sentense p{
    margin-bottom : 18px;
    line-height   : 24px;
}
.article_page_sentense h3{
    font-weight   : bold;
    padding       : 8px 10px 8px 15px;
    border-left   : 5px solid $baseColor;
    background    : #f2f2f2;
    font-size     : 23px;
    margin-top    : 30px;
    margin-bottom : 15px;
}
.article_page_sentense ul{
    padding    : 15px 20px;
    margin     : 15px 0;
    background : #f0f7ff;
    border     : dashed 2px #5b8bd0;
}
.article_page_sentense ul li{
    line-height : 25px;
}
.article_page_ad img{
    border-radius : 5px;
}
