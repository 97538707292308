//-
//-  Navigation & Menu Style
//-
//- *******************
//-  共通
//- *******************
#menu-trigger{

    display    : inline-block;
    transition : all .4s;
    box-sizing : border-box;

    span{

        display          : inline-block;
        transition       : all .4s;
        box-sizing       : border-box;
        position         : absolute;
        left             : 0;
        width            : 100%;
        height           : 4px;
        background-color : #333;
        border-radius    : 4px;

        &:nth-of-type(1){

            top : 0;

        }
        &:nth-of-type(2){

            top : 20px;

        }
        &:nth-of-type(3){

            bottom : 0;

        }
    }

    position   : relative;
    width      : 50px;
    height     : 44px;

}
//- *******************
//-  個別
//- *******************
#menu-trigger.active span{
    &:nth-of-type(1){
        -webkit-transform : translateY(20px) rotate(-45deg);
        transform         : translateY(20px) rotate(-45deg);
    }
    &:nth-of-type(2){
        left              : 50%;
        opacity           : 0;
        -webkit-animation : active-menu-bar02 .8s forwards;
        animation         : active-menu-bar02 .8s forwards;
    }
    &:nth-of-type(3){
        -webkit-transform : translateY(-20px) rotate(45deg);
        transform         : translateY(-20px) rotate(45deg);
    }
}
@-webkit-keyframes active-menu-bar02{
    100%{
        height : 0;
    }
}
@keyframes active-menu-bar02{
    100%{
        height : 0;
    }
}