//-
//- Icon Style
//-

.fa{

    &.fa-chevron-right{

        margin-left : 8px;
        font-size   : 1.6rem;
        position    : relative;
        bottom      : 1px;

    }

}