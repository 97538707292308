//-
//- Mixin Style
//-
//- Clearfix
@mixin clearfix{
    &::after{
        content : "";
        display : block;
        clear   : both;
    }
}
//- フォントサイズ
@function get_vw($size, $viewport:320){
    $rate : 100 / $viewport;
    @return $rate * $size * 1vw;
}
@mixin fz_vw($font_size:10){
    font-size : $font_size * 1px;
    font-size : get_vw($font_size);
}
//- ブレークポイント
@mixin break-point($point){

    @if $point == xl{

        @media screen and (min-width : 1200px){
            @content;
        }

    } @else if $point == lg{

        @media screen and (max-width : 1200px){
            @content;
        }

    } @else if $point == md{

        @media screen and (max-width : 768px){
            @content;
        }

    } @else if $point == sm{

        @media screen and (max-width : 544px){
            @content;
        }

    } @else if $point == 414{ //- iphone以下

        @media screen and (max-width : 414px){
            @content;
        }

    } @else if $point == 375{ //- ギャラクシー以下

        @media screen and (max-width : 414px){
            @content;
        }

    }

}
@mixin gradient($start-color, $end-color, $orientation){
    background : $start-color;
    @if $orientation == vertical{
        // vertical
        background : -moz-linear-gradient(top, $start-color 0%, $end-color 100%);
        background : -webkit-gradient(linear, left top, left bottom, color-stop(0%, $start-color), color-stop(100%, $end-color));
        background : -webkit-linear-gradient(top, $start-color 0%, $end-color 100%);
        background : -o-linear-gradient(top, $start-color 0%, $end-color 100%);
        background : -ms-linear-gradient(top, $start-color 0%, $end-color 100%);
        background : linear-gradient(to bottom, $start-color 0%, $end-color 100%);
        filter     : progid:DXImageTransform.Microsoft.gradient(startColorstr='$start-color', endColorstr='$end-color', GradientType=0);
    } @else if $orientation == horizontal{
        // horizontal
        background : -moz-linear-gradient(left, $start-color 0%, $end-color 100%);
        background : -webkit-gradient(linear, left top, right top, color-stop(0%, $start-color), color-stop(100%, $end-color));
        background : -webkit-linear-gradient(left, $start-color 0%, $end-color 100%);
        background : -o-linear-gradient(left, $start-color 0%, $end-color 100%);
        background : -ms-linear-gradient(left, $start-color 0%, $end-color 100%);
        background : linear-gradient(to right, $start-color 0%, $end-color 100%);
        filter     : progid:DXImageTransform.Microsoft.gradient(startColorstr='$start-color', endColorstr='$end-color', GradientType=1);
    } @else{
        // radial
        background : -moz-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
        background : -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, $start-color), color-stop(100%, $end-color));
        background : -webkit-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
        background : -o-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
        background : -ms-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
        background : radial-gradient(ellipse at center, $start-color 0%, $end-color 100%);
        filter     : progid:DXImageTransform.Microsoft.gradient(startColorstr='$start-color', endColorstr='$end-color', GradientType=1);
    }
}
//- 自動的にナンバーをつけてくれるmixin
@mixin auto-numbers($numbered-element, $sep, $counter: item, $nested-parent: false ){
    $sel : ();
    @if $nested-parent{
        $sel : append($sel, unquote($nested-parent));

        #{$nested-parent}{
            list-style  : none;
            margin-left : 0;
        }
    }
    $sel : append($sel, unquote('&'), comma);

    #{$sel}{
        counter-reset : #{$counter};
        > #{$numbered-element}{
            &:before{
                counter-increment : #{$counter};
                content           : if($nested-parent, counters(#{$counter}, "#{$sep} ") "#{$sep} ", counter(#{$counter}) "#{$sep} ");
            }
        }
    }
}