@charset "UTF-8";
/* ==========================================================================
   Normalize.scss settings
   ========================================================================== */
/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */
/* Base
   ========================================================================== */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *  `em` units.
 */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none; }

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */
a:active, a:hover {
  outline: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Addresses styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
  border: 0; }

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px; }

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  box-sizing: content-box;
  height: 0; }

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto; }

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal; }

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  box-sizing: content-box;
  /* 2 */ }

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto; }

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold; }

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

html {
  font-size: 62.5%;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -o-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased; }

body {
  font-size: 16px;
  font-size: 1.6rem;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Noto Sans Japanese", "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, Meiryo, sans-serif;
  line-height: 1.6;
  color: #333;
  letter-spacing: -0.001em;
  -webkit-text-size-adjust: 100%;
  font-feature-settings: "pwid";
  width: 100%; }
  @media screen and (min-width: 1200px) {
    body {
      width: 100%; } }
  @media screen and (max-width: 1200px) {
    body {
      width: 100%; } }
  @media screen and (max-width: 768px) {
    body {
      width: 100%; } }
  @media screen and (max-width: 544px) {
    body {
      width: 100%; } }
  @media screen and (max-width: 414px) {
    body {
      width: 100%; } }
  @media screen and (max-width: 414px) {
    body {
      width: 100%; } }

svg:not(:root) {
  overflow: hidden; }

select {
  -moz-appearance: none;
  -webkit-appearance: none; }

select::-ms-expand {
  display: none; }

select::-ms-value {
  color: currentColor; }

a {
  transition: all 3s 0s ease; }

a:active,
a:hover {
  outline: 0;
  text-decoration: none;
  color: #00b3ee; }

a:hover img,
a:active img {
  opacity: 0.7;
  filter: alpha(opacity=70);
  -moz-opacity: 0.7;
  -ms-filter: alpha(opacity=70); }

input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; }

input[type="number"] {
  width: auto; }

input[type="search"] {
  -webkit-appearance: textfield; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

img {
  max-width: 100%;
  vertical-align: bottom;
  height: auto; }

.ie8 img {
  width: auto; }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: "";
    display: block;
    clear: both; }

.left {
  float: left; }

.right {
  float: right; }

.border-box {
  box-sizing: border-box; }

h1, h2, h3, h4, h5, h6, aside, details, figcaption,
figure, footer, header, hgroup, main, menu, nav, summary,
section, article, input, textarea, div, span, ul, li, a {
  box-sizing: border-box; }

h1, h2, h3, h4, h5, h6 {
  line-height: 1; }

@media screen and (min-width: 1200px) {
  .pc {
    display: block; } }

@media screen and (max-width: 1200px) {
  .pc {
    display: block; } }

@media screen and (max-width: 768px) {
  .pc {
    display: none; } }

@media screen and (min-width: 1200px) {
  .tab {
    display: none; } }

@media screen and (max-width: 1200px) {
  .tab {
    display: none; } }

@media screen and (max-width: 768px) {
  .tab {
    display: block; } }

@media screen and (max-width: 544px) {
  .tab {
    display: none; } }

@media screen and (min-width: 1200px) {
  .phone {
    display: none; } }

@media screen and (max-width: 1200px) {
  .phone {
    display: none; } }

@media screen and (max-width: 768px) {
  .phone {
    display: none; } }

@media screen and (max-width: 544px) {
  .phone {
    display: block; } }

a {
  text-decoration: none; }

ul,
li {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.middle {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0; }

.clear {
  clear: both; }

.relative {
  position: relative; }

.absolute {
  position: absolute; }

.flex {
  display: flex; }

.l-p {
  padding-top: 60px;
  padding-bottom: 60px; }

.l-m {
  margin-top: 60px;
  margin-bottom: 60px; }

.aaa {
  background-color: red; }

#menu-trigger {
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box;
  position: relative;
  width: 50px;
  height: 44px; }
  #menu-trigger span {
    display: inline-block;
    transition: all .4s;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #333;
    border-radius: 4px; }
    #menu-trigger span:nth-of-type(1) {
      top: 0; }
    #menu-trigger span:nth-of-type(2) {
      top: 20px; }
    #menu-trigger span:nth-of-type(3) {
      bottom: 0; }

#menu-trigger.active span:nth-of-type(1) {
  -webkit-transform: translateY(20px) rotate(-45deg);
  transform: translateY(20px) rotate(-45deg); }

#menu-trigger.active span:nth-of-type(2) {
  left: 50%;
  opacity: 0;
  -webkit-animation: active-menu-bar02 .8s forwards;
  animation: active-menu-bar02 .8s forwards; }

#menu-trigger.active span:nth-of-type(3) {
  -webkit-transform: translateY(-20px) rotate(45deg);
  transform: translateY(-20px) rotate(45deg); }

@-webkit-keyframes active-menu-bar02 {
  100% {
    height: 0; } }

@keyframes active-menu-bar02 {
  100% {
    height: 0; } }

p {
  margin: 0;
  line-height: 1.6; }

.container {
  width: 1000px; }
  .container.wide_container {
    width: 1140px; }

.m-header.m-tglBtn-wrapper.mm-slideout {
  display: none; }

.header {
  border-bottom: 5px solid #0e1c63; }
  .header__info {
    justify-content: space-between; }
  .header__logo {
    margin-left: 10%;
    padding-top: 25px; }
    .header__logo a {
      display: block; }
  .header__tell, .header__mail {
    font-weight: bold;
    width: 270px;
    padding-top: 15px;
    padding-bottom: 15px; }
  .header__tell {
    background-image: url("../images/phone.svg");
    background-repeat: no-repeat;
    background-size: 40px;
    padding-left: 52px;
    background-position: 0 center; }
    .header__tell p {
      font-size: 3.3rem;
      line-height: 1.1;
      letter-spacing: -2px; }
    .header__tell span {
      font-size: 1.4rem;
      display: block; }
  .header__mail {
    text-align: center;
    background-image: url("../images/sla.svg");
    background-repeat: no-repeat;
    background-size: cover; }
    .header__mail a {
      color: #fff;
      line-height: 1; }
  .header__mail-time {
    font-size: 3.3rem; }
  .header__mail-accept {
    font-size: 1.8rem;
    position: relative;
    bottom: 1px; }
  .header__mail-ex {
    display: block; }

.mainV img {
  width: 100%; }

.top_headline_sub {
  padding-top: 150px; }

.top_headline_sub h2,
.top_headline_main h2 {
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2); }

.top_headline_sub h2 {
  font-size: 27px;
  font-weight: bold;
  border-bottom: 3px solid #ff5900;
  margin-right: 340px;
  padding-bottom: 5px;
  margin-bottom: 10px; }

.top_headline_main h2 {
  font-size: 55px;
  font-weight: bold; }

.gnav {
  background-color: #0e1c63;
  box-shadow: 0px 3px 2px 1px;
  padding-top: 15px;
  padding-bottom: 15px; }
  .gnav_each {
    border-right: 1px solid #4a58a7;
    border-left: 1px solid #4a58a7; }
    .gnav_each + .gnav_each {
      border-left: 0;
      border-right: 1px solid #4a58a7; }
    .gnav_each a {
      color: #fff;
      font-size: 1.6rem;
      text-align: center;
      font-weight: bold;
      padding-top: 15px;
      padding-bottom: 15px;
      display: block;
      transition: all 0.2s 0s ease; }
      .gnav_each a:hover {
        color: #ccd8ff; }

.border_line {
  margin: 40px auto;
  width: 150px;
  height: 3px;
  background-color: #0e1c63; }

.top_sentense {
  text-align: center;
  background-image: url("../images/pic2.png");
  background-size: cover; }
  .top_sentense p {
    font-weight: bold;
    font-size: 2rem; }
  .top_sentense h2 {
    font-size: 4rem;
    color: #0e1c63;
    font-weight: bold; }
  .top_sentense h3 {
    font-size: 3.6rem;
    color: #0e1c63;
    font-weight: bold; }
    .top_sentense h3 span {
      font-size: 21px;
      display: inline-block;
      margin-left: 12px;
      position: relative;
      top: 1px; }
  .top_sentense-img {
    margin-top: 40px;
    margin-bottom: 50px; }
  .top_sentense-btm {
    border: 3px solid #0e1c63;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2); }
    .top_sentense-btm.l-m {
      margin-bottom: 0; }
    .top_sentense-btm.l-p {
      padding-top: 50px;
      padding-bottom: 45px; }
    .top_sentense-btm .ad-3,
    .top_sentense-btm .ad-tsu {
      color: #ff5900;
      position: relative;
      font-style: italic;
      display: inline-block; }
    .top_sentense-btm .ad-3 {
      font-size: 4.5rem;
      line-height: 1;
      top: 3px;
      margin-right: 3px; }
    .top_sentense-btm .ad-tsu {
      margin-right: 5px; }

.subtitle {
  background-color: #0e1c63;
  text-align: center; }
  .subtitle h2 {
    padding: 45px 0 42px;
    color: #fff;
    font-size: 3.3rem;
    font-weight: bold;
    margin: 0; }
  .subtitle__line, .subtitle__en {
    display: inline-block;
    font-weight: 100;
    line-height: 1;
    position: relative; }
  .subtitle__line {
    font-size: 21px;
    margin-left: 12px;
    margin-right: 12px;
    bottom: 7px; }
  .subtitle__en {
    font-family: abel;
    font-size: 18px;
    bottom: 6px; }

.service_each {
  background-color: #0e1c63;
  background-image: url("../images/service_bgImg.svg");
  background-repeat: no-repeat;
  background-position: right -50px;
  border-radius: 3px;
  padding: 30px 50px 35px;
  margin-bottom: 30px; }
  .service_each:nth-last-of-type(1) {
    margin-bottom: 0; }
  .service_each h4 {
    padding-left: 12px;
    color: #ff5900;
    font-weight: bold;
    font-size: 3.3rem;
    margin-bottom: 15px;
    line-height: 1.25; }
    .service_each h4 span {
      display: inline-block;
      margin-right: 10px;
      font-size: 4.5rem;
      position: relative;
      top: 4px; }
  .service_each p {
    color: #fff;
    font-size: 1.8rem;
    font-weight: bold; }
  .service_each_left {
    display: flex;
    justify-content: center;
    flex-direction: column; }
  .service_each_right img {
    width: 200px;
    display: block;
    margin: auto; }

.top_service_click {
  margin-top: 50px; }
  .top_service_click a {
    text-align: center;
    background-color: #fff;
    color: #222;
    border: 1px solid black;
    border-radius: 3px;
    padding: 15px 0;
    font-weight: bold;
    font-size: 2rem;
    display: block;
    width: 320px;
    margin: auto;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2); }

.top_office {
  background-image: url("../images/pic6.png");
  background-size: cover; }
  .top_office.l-p {
    padding-top: 90px;
    padding-bottom: 85px; }
  .top_office-wrapper {
    justify-content: space-around; }
  .top_office_left {
    position: relative;
    right: 5%; }
    .top_office_left p {
      line-height: 1.4;
      margin-bottom: 10px;
      padding-right: 5px;
      text-align: right;
      font-weight: bold; }
  .top_office_link {
    text-align: center;
    margin-top: 40px; }
    .top_office_link a {
      text-align: center;
      background-color: #fff;
      color: #222;
      border: 1px solid black;
      border-radius: 3px;
      padding: 15px 0;
      font-weight: bold;
      font-size: 2rem;
      display: block;
      width: 320px;
      margin: auto;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2); }

.top_tokyo_office img {
  width: 350px; }

.top_tokyo_office h4 {
  padding-top: 85px;
  text-align: right;
  font-weight: bold;
  margin-bottom: 30px;
  font-size: 3.5rem; }

.japan-map-img {
  position: relative; }

.japan-map-sendai {
  position: absolute;
  top: 350px;
  right: 30px; }

.japan-map-tokyo {
  position: absolute;
  top: 475px;
  right: 90px; }

.japan-map-fukui {
  position: absolute;
  top: 425px;
  left: 160px; }

.japan-map-osaka {
  position: absolute;
  top: 510px;
  left: 120px; }

.japan-map-seiyo {
  position: absolute;
  top: 610px;
  left: 175px; }

.japan-map-nagoya {
  position: absolute;
  top: 550px;
  right: 185px; }

.japan-map-link {
  top: 0;
  height: 100%;
  width: 100%; }
  .japan-map-link a {
    padding: 8px 15px;
    border: 1px solid white;
    border-radius: 2px;
    background-color: rgba(14, 28, 99, 0.8);
    color: #fff;
    font-weight: bold;
    font-size: 1.6rem;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5); }

.footer {
  background-color: #0e1c63;
  text-align: center;
  color: #fff;
  font-size: 14px; }
  .footer_logo img {
    margin: 45px 0px; }
  .footer_faq_left {
    display: flex;
    justify-content: center;
    flex-direction: column; }
    .footer_faq_left p {
      font-size: 1.8rem;
      font-weight: bold; }
  .footer_faq_right a {
    text-align: center;
    border: 1px solid #fff;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.14);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.7);
    padding: 15px 0;
    color: #fff;
    font-weight: bold;
    font-size: 2rem;
    display: block;
    width: 320px;
    margin: auto;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2); }
  .footer_faq {
    padding: 30px 0;
    border-bottom: 1px solid #2d3d8a; }
  .footer_link {
    margin-top: 50px;
    margin-bottom: 50px;
    justify-content: center; }
    .footer_link a {
      display: inline-block;
      padding-top: 7px;
      padding-bottom: 7px;
      color: #fff;
      text-align: center; }
    .footer_link-items {
      margin-right: 30px; }
  .footer_logo {
    background-color: #000048; }

.copyright {
  padding-bottom: 10px;
  font-size: 1.2rem; }

.top_news_category, .top_news_date {
  float: left; }

.top_news_category {
  margin-right: 10px; }
  .top_news_category p {
    padding: 5px 15px 4px;
    border-radius: 3px;
    background-color: #ffd690;
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
    display: inline-block; }

.top_news_date p {
  font-weight: bold;
  line-height: 1;
  padding-top: 5px; }

.top_news_detail {
  margin-bottom: 5px; }

.top_news_sentence p {
  padding-top: 3px;
  padding-bottom: 5px; }

.top_news_each {
  margin-bottom: 20px; }

.top_news_sentence {
  padding-bottom: 10px;
  border-bottom: 1px dotted #ccc; }

.top_news {
  margin: 50px 0; }

.staff {
  flex-wrap: wrap; }
  .staff.container {
    width: 1140px; }
  .staff-each {
    width: 24%;
    margin-bottom: 20px;
    margin-right: 10px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2); }
    .staff-each:nth-of-type(4n) {
      margin-right: 0; }
  .staff-each-top-slash img {
    width: 100%; }
  .staff-each-bottom {
    background-color: #0e1c63;
    text-align: center;
    color: white;
    background-image: url("../images/sla-u-2.png");
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: contain; }
  .staff-each-bottom-name {
    padding-top: 12px;
    padding-bottom: 12px; }
    .staff-each-bottom-name p {
      font-weight: bold;
      font-size: 1.8rem; }
    .staff-each-bottom-name span {
      display: block;
      font-size: 12px; }
  .staff-each-top-face {
    background-image: url("../images/sla-t-2.png");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: contain;
    padding-top: 20px;
    padding-bottom: 20px; }
    .staff-each-top-face img {
      width: 140px;
      border: 4px solid #f9f9f9;
      border-radius: 50%;
      display: block;
      margin: auto;
      background-color: #fff; }
  .staff-single {
    border: 3px solid #0e1c63;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    background-image: url("../images/sla-t.png"), url("../images/sla-u.png");
    background-repeat: no-repeat, no-repeat;
    background-position: left top, right bottom;
    background-size: contain, contain;
    padding: 80px 30px 70px;
    width: 800px; }
  .staff-single-content-left, .staff-single-content-right {
    float: left; }
  .staff-single-content, .staff-single-content-explain {
    padding: 0px 30px; }
  .staff-single-content-right h2 {
    color: #0e1c63;
    font-size: 3.3rem;
    font-weight: bold;
    margin-bottom: 20px; }
  .staff-single-content-right p {
    font-size: 1.8rem;
    line-height: 1.7;
    font-weight: bold; }
  .staff-single-content-explain {
    margin-top: 40px; }
    .staff-single-content-explain h3 {
      font-weight: bold;
      font-size: 2.3rem;
      margin-bottom: 12px; }
  .staff-single-content-right {
    margin-left: 80px;
    padding-top: 10px; }
  .staff-single-bottom-slash {
    margin-top: -15px; }

.servicedetail_page {
  margin: 60px 0; }

.servicedetail_title h2 {
  font-size: 30px;
  font-weight: bold; }

.servicedetail_gaiyo {
  background-color: #f3f3f3;
  padding: 20px;
  border-radius: 3px;
  font-weight: bold;
  margin-top: 15px; }

.servicedetail_content h3 {
  text-align: center;
  background-color: #0e1c63;
  color: white;
  font-size: 25px;
  font-weight: bold;
  padding: 10px;
  margin-top: 40px;
  margin-bottom: 20px;
  border-radius: 3px; }

.servicedetail_content h4 {
  margin-top: 20px;
  padding: 0.25em 0.5em;
  /*上下 左右の余白*/
  color: #494949;
  /*文字色*/
  background: transparent;
  /*背景透明に*/
  border-left: solid 5px #7db4e6;
  /*左線*/
  font-weight: bold;
  font-size: 20px; }

.servicedetail_content ul li {
  list-style: disc;
  margin-left: 20px; }

p.asta {
  font-size: 14px;
  margin-top: 10px; }

.faq_barner {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 60px; }

.faq_barner img {
  width: 70%; }

.servicedetail_content p {
  line-height: 24px; }

.service_subtitle {
  margin-top: 60px;
  margin-bottom: 30px; }

.service_subtitle_jap {
  color: #0e1c63;
  font-size: 29px;
  font-weight: bold;
  padding: .25em 0 .25em .75em;
  border-left: 4px solid #0e1c63; }

.service_subtitle_eng {
  color: #7d7b7b;
  margin-left: 10px;
  font-size: 25px; }

.service_subtitle_dot {
  color: #0e1c63;
  font-weight: bold;
  font-size: 29px;
  margin-left: 10px; }

.service_table_img {
  background-color: #0e1c63;
  padding: 0 10px;
  width: 85px;
  height: 90px; }

.service_table_img p {
  color: white;
  text-align: center;
  padding-top: 6px;
  font-size: 14px;
  font-weight: bold; }

.service_table_img img {
  background-color: #0e1c63;
  padding: 7px; }

.service_table_img, .service_table_title {
  float: left; }

.service_table_title {
  margin-top: 32px;
  margin-left: 14px; }

.service_table_title h3 {
  font-size: 20px;
  font-weight: bold;
  color: black;
  width: 365px; }

.service_table_each:hover {
  opacity: 0.7; }

.service_table {
  margin-bottom: 30px; }

.service_page {
  padding-bottom: 40px; }

.saiyo h2 {
  margin: 40px 0 25px 0;
  font-size: 25px;
  background-color: #0e1c63;
  color: white;
  padding: 15px;
  font-weight: bold; }

.row.saiyo_each_col {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px dotted #ccc; }

.saiyo_title p {
  font-weight: bold; }

.saiyo_intro_img, .saiyo_intro_sentence {
  float: left; }

.saiyo_intro_sentence {
  padding: 40px 15px; }

.saiyo_intro_sentence p {
  font-size: 18px;
  line-height: 25px;
  font-weight: bold; }

.saiyo {
  margin: 35px 0px 60px 0; }

.saiyo_intro {
  width: 700px;
  margin: auto;
  margin-bottom: -49px; }

.office_page {
  margin: 60px 0; }

.tokyo_office_img img {
  width: 420px;
  box-shadow: 28px 28px 0px #ccc;
  margin-top: 20px; }

.fukui_office_img {
  text-align: right; }

.fukui_office_img img {
  width: 420px;
  box-shadow: -28px 28px 0px #ccc;
  margin-top: 20px; }

.office_title {
  border-bottom: 3px solid #ccc;
  padding-left: 3px;
  margin-bottom: 10px;
  width: 80%; }

.office_title h2 {
  font-weight: bold;
  font-size: 21px; }

.office_add p {
  padding-left: 3px; }

.office_map {
  margin-top: 10px;
  border: 2px solid #cccccc; }

.office_col {
  margin-bottom: 60px; }

.office_title h2 {
  float: left; }

.office_title h3 {
  font-size: 44px;
  margin-top: -16px;
  color: #ccc;
  font-family: 'Meta Normal', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin-bottom: -3px;
  padding-right: 10px;
  float: right; }

.jyoho_odd, .jyoho_even {
  padding: 10px 0;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2); }

.jyoho_odd {
  border: 1px solid #ccc; }

.jyoho_even {
  background-color: #ccc; }

.jyoho_sentense {
  margin: 50px 0; }

.jyoho_left {
  text-align: center;
  font-weight: bold; }

.jyoho_right_office {
  margin-bottom: 10px; }

.gaiyo_nav {
  margin-top: 40px; }

.gaiyo_title_name, .gaiyo_map {
  float: left; }

.gaiyo_map {
  margin-top: -4px; }

.gaiyo_map a {
  background-color: #0e1c63;
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  margin-left: 5px;
  font-size: 13px; }

.jisseki_box {
  border: 1px solid #0e1c63;
  width: 32%;
  float: left;
  height: 140px; }

.jisseki_box + .jisseki_box {
  margin-left: 2%; }

.jisseki_title {
  width: 40%;
  background-color: #0e1c63;
  color: white;
  text-align: center;
  font-weight: bold;
  padding: 3px; }

.jisseki_sentense {
  margin: 10px;
  padding-bottom: 10px;
  color: #0e1c63;
  border-bottom: 1px dotted #0e1c63;
  height: 55px; }

.jisseki_client {
  margin: 10px;
  border: 1px solid #0e1c63;
  text-align: center;
  padding: 4px;
  font-weight: bold;
  color: #0e1c63; }

.jisseki_sentense p {
  font-size: 17px;
  line-height: 22px; }

.jisseki_col {
  margin-bottom: 17px; }

.jisseki_box.jisseki_box2 {
  background-color: #0e1c63;
  padding: 35px;
  font-weight: bold; }

.jisseki_box.jisseki_box2 a {
  color: white; }

.jisseki_client p {
  font-size: 14px; }

.bread {
  padding: 5px 0 3px;
  background-color: #f1f1f1;
  font-size: 1.3rem; }
  .bread span {
    display: inline-block;
    margin-left: 6px;
    margin-right: 7px;
    position: relative;
    bottom: 1px; }
    .bread span.current-page {
      margin-left: 0;
      bottom: 0; }

.gaiyo_sentense.l-m {
  margin-top: 50px; }

.gaiyo_sentense p {
  font-weight: bold;
  font-size: 1.8rem;
  text-align: center; }

.kasou_pic {
  height: 160px;
  border-bottom: 3px solid #ff5900;
  background-image: url("../images/kasou-top.png");
  background-size: cover; }

.kasou_headline_main h2 {
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
  color: white;
  font-size: 36px;
  text-align: center;
  padding: 60px;
  font-weight: bold; }

.gaiyo_first_box a,
.gaiyo_second_box a {
  color: black;
  font-size: 19px;
  font-weight: bold;
  display: block;
  padding: 30px; }

.gaiyo_left_box, .gaiyo_right_box {
  float: left;
  width: 49%;
  text-align: center;
  border: 1px solid black;
  border-radius: 3px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2); }

.gaiyo_right_box {
  float: right; }

.gaiyo_first_box.clearfix {
  margin-bottom: 15px; }

.gaiyo_second_box.clearfix {
  margin-bottom: 45px; }

.faq_page {
  margin: 60px 0; }

.faq_form {
  background-color: #0e1c63;
  color: white;
  padding: 70px 60px 100px 60px;
  margin-top: 30px; }

.faq_form h2 {
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 30px; }

.faq_box_blank {
  background-color: white;
  height: 30px;
  margin: 12px;
  border-radius: 3px; }

.faq_title {
  padding: 15px;
  font-weight: bold; }

.faq_pre_title, .faq_the_title {
  float: left; }

.faq_pre_title.must {
  background-color: #ff5900 red;
  padding: 3px 5px;
  border-radius: 5px;
  margin-right: 10px; }

.faq_pre_title.not_must {
  background-color: #8e8e8e;
  padding: 3px 5px;
  border-radius: 5px;
  margin-right: 10px; }

.faq_the_title {
  padding: 3px 5px; }

.faq_box_blank.faq_box_blank_long {
  height: 130px; }

.faq_privacy {
  background-color: white;
  color: black;
  padding: 30px;
  margin: 40px;
  border-radius: 3px; }

.faq_privacy_agree {
  text-align: center;
  font-weight: bold; }

.faq_submit a {
  background-color: white;
  color: black;
  padding: 20px 95px;
  font-size: 18px;
  border-radius: 3px;
  font-weight: bold; }

.faq_submit {
  text-align: center;
  margin-top: 60px; }

.article_wrapper {
  flex-wrap: wrap;
  justify-content: space-between; }

.article {
  width: 49%; }
  .article_date, .article_category {
    float: left; }
  .article img {
    border-radius: 3px; }
  .article_category {
    position: relative;
    top: 3px; }
    .article_category p {
      margin-left: 10px;
      padding: 3px 6px 2px;
      border-radius: 2px;
      background-color: #0e1c63;
      color: #ffffff;
      font-weight: bold;
      font-size: 1.1rem;
      line-height: 1; }
  .article_date p {
    color: #aaaaaa;
    font-weight: bold;
    font-size: 2rem;
    font-family: Abel;
    line-height: 1.1; }
  .article_bottom {
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cg fill='%230e1c63' fill-opacity='0.34'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");
    height: 8px;
    margin-top: 5px;
    margin-bottom: 30px; }
  .article-detail {
    margin-top: 15px; }

.title {
  font-weight: bold; }
  .title p {
    font-size: 1.8rem;
    margin: 18px 0 12px;
    color: #222;
    transition: all 0.2s 0s ease; }
    .title p:hover {
      color: #00b3ee; }

.blog.l-m {
  margin-top: 40px; }

.blog_right {
  padding-left: 0; }
  .blog_right_category, .blog_right_search, .blog_right_popular, .blog_right_sc, .blog_right_bar {
    margin-bottom: 15px;
    border-radius: 3px;
    border: 2px solid #0e1c63;
    border-bottom: 5px solid #0e1c63; }
    .blog_right_category h3, .blog_right_search h3, .blog_right_popular h3, .blog_right_sc h3, .blog_right_bar h3 {
      background-color: #0e1c63;
      color: #fff;
      text-align: center;
      padding: 14px 0 13px;
      font-weight: bold;
      margin-bottom: 0; }
  .blog_right_category_each {
    border-bottom: 1px solid #0e1c63; }
    .blog_right_category_each a {
      display: block;
      color: #0e1c63;
      text-align: center;
      font-weight: bold;
      padding: 20px 0 18px;
      line-height: 1;
      background: -moz-linear-gradient(270deg, white 0%, white 1%, white 50%, #f7f7f7 51%, #f7f7f7 100%);
      /* ff3.6+ */
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, white), color-stop(1%, white), color-stop(50%, white), color-stop(51%, #f7f7f7), color-stop(100%, #f7f7f7));
      /* safari4+,chrome */
      background: -webkit-linear-gradient(270deg, white 0%, white 1%, white 50%, #f7f7f7 51%, #f7f7f7 100%);
      /* safari5.1+,chrome10+ */
      background: -o-linear-gradient(270deg, white 0%, white 1%, white 50%, #f7f7f7 51%, #f7f7f7 100%);
      /* opera 11.10+ */
      background: -ms-linear-gradient(270deg, white 0%, white 1%, white 50%, #f7f7f7 51%, #f7f7f7 100%);
      /* ie10+ */
      background: linear-gradient(180deg, white 0%, white 1%, white 50%, #f7f7f7 51%, #f7f7f7 100%);
      /* w3c */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#F7F7F7', GradientType=0);
      /* ie6-9 */ }
  .blog_right_search_box {
    padding: 10px; }
  .blog_right_popular_each {
    border-bottom: 1px solid #0e1c63;
    padding: 5px 0; }
    .blog_right_popular_each img, .blog_right_popular_each p {
      float: left; }
    .blog_right_popular_each img {
      width: 39%;
      border-radius: 3px;
      padding-left: 5px; }
    .blog_right_popular_each p {
      width: 61%;
      font-size: 14px;
      padding: 5px; }
  .blog_right_sc p {
    padding: 10px;
    font-size: 1.4rem; }

.article_capimg img {
  width: 100%;
  border-radius: 5px; }

.article_page_title h2 {
  font-size: 31px;
  margin: 15px 0 10px 0; }

.article_page_date, .article_page_category {
  float: left;
  margin-right: 10px;
  padding: 5px 7px; }

.article_page_detail p {
  font-size: 18px; }

.article_page_category {
  background-color: #0e1c63;
  border-radius: 5px;
  color: white; }

.article_page_writer {
  margin-top: 20px;
  background-color: #ececec;
  padding: 20px;
  border-radius: 5px; }

.article_page_writer_img, .article_page_writer_detail {
  float: left; }

.article_page_writer_img {
  width: 15%; }

.article_page_writer_detail {
  width: 85%;
  padding-left: 20px; }

.article_page_writer_img img {
  border-radius: 50%;
  border: 2px solid #ccc; }

.article_page_writer_detail h4 {
  font-size: 18px;
  padding-bottom: 5px; }

.article_page_writer_detail p {
  color: #757474; }

.article_page_sentense {
  margin-top: 30px; }

.article_page_sentense p {
  margin-bottom: 18px;
  line-height: 24px; }

.article_page_sentense h3 {
  font-weight: bold;
  padding: 8px 10px 8px 15px;
  border-left: 5px solid #0e1c63;
  background: #f2f2f2;
  font-size: 23px;
  margin-top: 30px;
  margin-bottom: 15px; }

.article_page_sentense ul {
  padding: 15px 20px;
  margin: 15px 0;
  background: #f0f7ff;
  border: dashed 2px #5b8bd0; }

.article_page_sentense ul li {
  line-height: 25px; }

.article_page_ad img {
  border-radius: 5px; }

.aaa {
  background-color: red; }

.fa.fa-chevron-right {
  margin-left: 8px;
  font-size: 1.6rem;
  position: relative;
  bottom: 1px; }

.m-tglBtn-wrapper {
  text-align: right; }
  .m-tglBtn-wrapper .m-tglBtn {
    display: inline-block;
    position: relative; }

.m-header {
  position: fixed;
  top: 0;
  height: 60px;
  background-color: #efefef;
  width: 100%; }

#menu-trigger {
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box;
  position: relative;
  width: 50px;
  height: 44px; }
  #menu-trigger span {
    display: inline-block;
    transition: all .4s;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #333;
    border-radius: 4px; }
    #menu-trigger span:nth-of-type(1) {
      top: 0; }
    #menu-trigger span:nth-of-type(2) {
      top: 20px; }
    #menu-trigger span:nth-of-type(3) {
      bottom: 0; }

#menu-trigger.active span:nth-of-type(1) {
  -webkit-transform: translateY(20px) rotate(-45deg);
  transform: translateY(20px) rotate(-45deg); }

#menu-trigger.active span:nth-of-type(2) {
  left: 50%;
  opacity: 0;
  -webkit-animation: active-menu-bar02 .8s forwards;
  animation: active-menu-bar02 .8s forwards; }

#menu-trigger.active span:nth-of-type(3) {
  -webkit-transform: translateY(-20px) rotate(45deg);
  transform: translateY(-20px) rotate(45deg); }

@-webkit-keyframes active-menu-bar02 {
  100% {
    height: 0; } }

@keyframes active-menu-bar02 {
  100% {
    height: 0; } }
