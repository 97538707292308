@charset "utf-8";

html{

    font-size                : 62.5%;
    -ms-overflow-style       : -ms-autohiding-scrollbar;
    overflow-y               : scroll;
    -webkit-text-size-adjust : 100%;
    -ms-text-size-adjust     : 100%;
    -o-text-size-adjust      : 100%;
    text-size-adjust         : 100%;
    -webkit-font-smoothing   : antialiased;
    -moz-osx-font-smoothing  : grayscale;
    font-smoothing           : antialiased;

}
body{

    font-size                : 16px;
    font-size                : 1.6rem;
    font-family              : $fontFamily;
    line-height              : 1.6;
    color                    : $fontColor;
    letter-spacing           : -0.001em;
    -webkit-text-size-adjust : 100%;
    //- 値 :"palt" or "pwid" or "pkna"
    font-feature-settings    : "pwid";

    width                    : 100%;

    @include break-point(xl){
        width : 100%;
    }
    @include break-point(lg){
        width : 100%;
    }
    @include break-point(md){
        width : 100%;
    }
    @include break-point(sm){
        width : 100%;
    }
    @include break-point(414){
        width : 100%;
    }
    @include break-point(375){
        width : 100%;
    }

}
svg:not(:root){

    overflow : hidden;

}
select{

    -moz-appearance    : none;
    -webkit-appearance : none;

}
select::-ms-expand{

    display : none;

}
select::-ms-value{

    color : currentColor;

}
a{

    transition : all 3s 0s ease;

}
a:active,
a:hover{

    outline         : 0;
    text-decoration : none;
    color           : #00b3ee;

}
a:hover img,
a:active img{

    opacity      : 0.7;
    filter       : alpha(opacity=70);
    -moz-opacity : 0.7;
    -ms-filter   : alpha(opacity=70);

}
input[type="button"],
input[type="reset"],
input[type="submit"]{

    -webkit-appearance : button;

}
input[type="number"]{

    width : auto;

}
input[type="search"]{

    -webkit-appearance : textfield;

}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration{

    -webkit-appearance : none;

}
img{
    max-width      : 100%;
    vertical-align : bottom;
    height         : auto;
}
.ie8 img{
    width : auto;
}
.clearfix{

    *zoom : 1;

    &:after{

        content : "";
        display : block;
        clear   : both;

    }

}
.left{

    float : left;

}
.right{

    float : right;

}
.border-box{

    box-sizing : border-box;

}
h1, h2, h3, h4, h5, h6, aside, details, figcaption,
figure, footer, header, hgroup, main, menu, nav, summary,
section, article, input, textarea, div, span, ul, li, a{

    box-sizing : border-box;

}
h1, h2, h3, h4, h5, h6{

    line-height : 1;

}
.pc{

    @include break-point(xl){

        display : block;

    }

    @include break-point(lg){

        display : block;

    }

    @include break-point(md){

        display : none;

    }

}
.tab{

    //- tab専用 (スマホ時はnone)

    @include break-point(xl){

        display : none;

    }

    @include break-point(lg){

        display : none;

    }

    @include break-point(md){

        display : block;

    }

    @include break-point(sm){

        display : none;

    }

}
.phone{

    @include break-point(xl){

        display : none;

    }

    @include break-point(lg){

        display : none;

    }

    @include break-point(md){

        display : none;

    }

    @include break-point(sm){

        display : block;

    }

}
a{
    text-decoration : none;

    &:hover{

    }
}
ul,
li{

    margin          : 0;
    padding         : 0;
    list-style-type : none;

}
.middle{

    margin   : auto;
    position : absolute;
    top      : 0;
    bottom   : 0;
    right    : 0;
    left     : 0;

}
.clear{

    clear : both;

}
.relative{

    position : relative;

}
.absolute{

    position : absolute;

}
.flex{

    display : flex;

}
.l-p{

    padding-top    : 60px;
    padding-bottom : 60px;

}
.l-m{

    margin-top    : 60px;
    margin-bottom : 60px;

}